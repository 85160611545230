"use client";

import { Box } from "@mui/material";
import { PublicHeaderStatic } from "@/app/(espace-pro)/_components/PublicHeader";
import { ErrorComponent, type ErrorProps } from "@/app/_components/ErrorComponent";
import { Footer } from "@/app/_components/Footer";
export default function ErrorPage(props: ErrorProps) {
  return <Box sx={{
    minHeight: "100vh",
    display: "grid",
    gridTemplateRows: "max-content 1fr min-content"
  }} data-sentry-element="Box" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      <PublicHeaderStatic data-sentry-element="PublicHeaderStatic" data-sentry-source-file="error.tsx" />
      <ErrorComponent {...props} data-sentry-element="ErrorComponent" data-sentry-source-file="error.tsx" />
      <Footer data-sentry-element="Footer" data-sentry-source-file="error.tsx" />
    </Box>;
}